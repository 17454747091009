import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@data-access/services';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [NgxSpinnerModule, CommonModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css'
})
export class LoadingComponent implements OnInit, OnDestroy {
  loading$ = this.loadingService.loading$;
  private isLoading: boolean = false;
  private $destroy = new Subject<void>();

  constructor(private loadingService : LoadingService, private spinner:NgxSpinnerService){}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.$destroy)).subscribe(result => this.manageSpinner(result))
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private manageSpinner(show:boolean){
    (this.isLoading = show)
    this.isLoading ?
      this.spinner.show() : this.spinner.hide();
  }
}
