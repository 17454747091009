import { Component } from '@angular/core';
import { Outlet, ResponseDTO } from '@models/index';
import { RestaurantCardComponent } from "../restaurant-card/restaurant-card.component";
import { Router } from '@angular/router';
import { OutletService } from '@data-access/services';
import { first } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-explore',
  standalone: true,
  templateUrl: './explore.component.html',
  styleUrl: './explore.component.css',
  imports: [RestaurantCardComponent, FormsModule]
})
export class ExploreComponent {
  restaurants: Outlet[] = [];
  searchResult: Outlet[] = [];
  searchQuery: string = '';
  searching: boolean = false;
  doneSearching: boolean = false;

  constructor(private router: Router, private outletService: OutletService) {
    this.getRestuarants();
  }

  navigateToExplore() {
    this.router.navigate(['/explore']);
  }

  search(event:KeyboardEvent) {
    if ((event.key === 'Return' || event.key === 'Enter') && this.searchQuery !== '') {
      this.searchResult = [];
      this.doneSearching = false;
      this.searching = true;

      this.findRestuarants(this.searchQuery)
    }
  }

  findRestuarants(query: string) {
    this.outletService.searchOutlet(query).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.doneSearching = true;
          this.searchResult = result.data as Outlet[];
        }
      },
      error: () => {

      }
    });
  }

  getRestuarants() {
    this.outletService.getOutlets().pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.restaurants = result.data as Outlet[];
        }
      },
      error: () => {

      }
    });
  }
}
