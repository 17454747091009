<a (click)="navigateToOutlet()">
    <div class="w-[160px] h-[152px] md:w-[200px] md:h-[192px] 2xl:w-[220px] rounded-md border border-gray-50 my-2 2xl:mt-5 mr-8 2xl:mr-14">
        <!-- Image -->
        <div class="flex h-[60%]">
            <img class="object-cover rounded-t-md" src={{image}} alt="outlet image"/>

        </div>
        <!-- Info -->
        <div class="flex flex-col text-[#0F172A] px-2 py-1">
            <span class="text-sm font-semibold md:text-base ">{{store}}</span>
            <span class="text-xs font-normal opacity-50 md:text-sm">{{outlet}}</span>
        </div>
    </div>
</a>