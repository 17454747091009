import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@data-access/services';
import { ToastrService } from 'ngx-toastr';

export const storeOwnerGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'storeowner';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};

export const ShenorGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'uncleshenor';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};


export const BusinessGuard: CanActivateFn = (route, state) => {
  const toastr = inject(ToastrService)
  const authService = inject(AuthService);
  const valid =  authService.getUserRole() === 'uncleshenor' || authService.getUserRole() === 'storeowner';
  if(!valid){
    toastr.error("Unauthorized");
  }
  return valid;
};