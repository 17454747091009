<app-dashboard>
    <span class="font-medium text-lg text-vendblocblue py-1">Hello, {{user?.name}}.</span>
    <span class="text_title">Welcome to <span class="text-vendblocpurple font-bold">VendBloc</span></span>

    <!-- <div class="hidden md:flex justify-between mt-5">
        @for (stat of stats; track $index) {
        <app-stat-card cardCount={{stat.count}} cardName={{stat.name}}
            iconBackgroundColor={{stat.color}}></app-stat-card>
        }
    </div> -->

    <!-- Content Div -->
    <p-card header="Personal Information" class="mt-5">
        <div class="flex space-x-5 mt-5">
            <div class="flex flex-col space-y-4">
                <span class="info_title">Name</span>
                <span class="info_title">Email</span>
                <span class="info_title">Address</span>
                <span class="info_title">Phone</span>
            </div>

            <div class="flex flex-col space-y-4">
                <span class="info_title opacity-70">{{user?.name}}</span>
                <span class="info_title opacity-70">{{user?.email}}</span>
                <span class="info_title opacity-70">{{user?.address}}</span>
                <span class="info_title opacity-70">{{user?.phone}}</span>
            </div>
        </div>
    </p-card>
</app-dashboard>