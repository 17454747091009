import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

export const errorhandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const toast = inject(ToastrService);
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        console.log(err);
        if (err.status === 401) {  
          console.error('Unauthorized request:', err);
        } 
        else if(err.status === 400){
          toast.error(err.error.title);
        }
        else if(err.status === 500){
          toast.error("We are terribly sorry, our servers are asleep");
        }
        else {
          console.error('HTTP error:', err);
        }
      } else {
        console.error('An error occurred:', err);
      }
      return throwError(() => err); 
    }))
};
