import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { AuthService, OrderService } from '@data-access/services';
import { Order, ResponseDTO, User } from '@models/index';
import { format } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { first } from 'rxjs';
import { ModalLayoutComponent } from "../layouts/modal-layout/modal-layout.component";
import { TableComponent } from "../table/table.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';

@Component({
    selector: 'app-my-orders',
    standalone: true,
    templateUrl: './my-orders.component.html',
    styleUrl: './my-orders.component.css',
    imports: [DashboardComponent, CommonModule, ModalLayoutComponent, TableModule, DialogModule, ButtonModule, TableComponent]
})
export class MyOrdersComponent {

    user: User | null = null;
    orders: Order[] = [];
    showDetail: boolean = false;
    dialogVisible: boolean = false;
    currentInvoiceOrder: any = null;

    tableCols: PrimeTableColumn[] = [
        { field: 'formattedDate', header: 'Date' },
        { field: 'store', header: 'Store' },
        { field: 'outlet', header: 'Outlet' },
        { field: 'deliveryMode', header: 'Delivery' },
        { field: 'status', header: 'Status' },
        { field: 'paid', header: 'Paid' },
        { field: 'totalAmount', header: 'Amount' }
      ];

    showDialog(index: number) {
        this.currentInvoiceOrder = this.orders[index];
        this.dialogVisible = true;
    }

    constructor(private orderService: OrderService,
        private authService: AuthService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.authService.user$.pipe(first()).subscribe((response) => {
            this.user = response;
        });
        this.fetchOrders();
    }

    showDetailEvent() {
        this.showDetail = !this.showDetail;
    }

    fetchOrders() {
        const email = this.user?.email as string;
        if (!email) this.router.navigate(['login']);
        this.orderService.getUserOrders(email).pipe(first()).subscribe({
            next: (result: ResponseDTO) => {
                if (result.status) {
                    const resultOrders = result.data as Order[]; 
                    this.orders = resultOrders.map((o: Order) => ({ ...o, formattedDate: format(o.orderDate as Date, 'dd MMM yyyy') }));
                }
            },
            error: (e) => {
                console.log(e);
            }
        });
    }
}
