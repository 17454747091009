import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalService } from '../../../data-access/services/local/local.service';

export const httptokenInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorage = inject(LocalService);
  const authToken = localStorage.getData('token');
  if(authToken === null)return next(req);

  const headersConfig: { [header: string]: string } = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  const isFormData = req.body instanceof FormData;

  if (isFormData) {
    delete headersConfig['Content-Type'];
  }

  const authReq = req.clone({
    setHeaders:headersConfig
  });
  return next(authReq);
};
