<p-card header="Stat" class="flex">
  <div
  class="flex items-center justify-center rounded-full h-14 w-14 {{iconBackgroundColor}}"
>
  <svg
    class="m-1"
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="30"
    viewBox="0 0 38 30"
    fill="none"
  >
    <path
      d="M37.336 12.0172V13.0497C37.336 13.905 36.6426 14.5983 35.7873 14.5983H35.2711L33.5861 26.3932C33.3681 27.9191 32.0613 29.0525 30.5199 29.0525H6.98404C5.44266 29.0525 4.13585 27.9191 3.91781 26.3932L2.23286 14.5983H1.71664C0.86132 14.5983 0.167969 13.905 0.167969 13.0497V12.0172C0.167969 11.1619 0.86132 10.4685 1.71664 10.4685H6.062L12.9523 0.994515C13.623 0.0722844 14.9144 -0.131688 15.8367 0.539078C16.759 1.20984 16.9629 2.50124 16.2922 3.42353L11.1685 10.4685H26.3355L21.2118 3.42347C20.541 2.50124 20.745 1.20978 21.6673 0.539014C22.5894 -0.131753 23.881 0.0721553 24.5517 0.994451L31.4419 10.4685H35.7873C36.6426 10.4685 37.336 11.1619 37.336 12.0172ZM20.3006 23.3741V16.147C20.3006 15.2917 19.6073 14.5983 18.752 14.5983C17.8967 14.5983 17.2033 15.2917 17.2033 16.147V23.3741C17.2033 24.2294 17.8967 24.9228 18.752 24.9228C19.6073 24.9228 20.3006 24.2294 20.3006 23.3741ZM27.5277 23.3741V16.147C27.5277 15.2917 26.8344 14.5983 25.9791 14.5983C25.1238 14.5983 24.4304 15.2917 24.4304 16.147V23.3741C24.4304 24.2294 25.1238 24.9228 25.9791 24.9228C26.8344 24.9228 27.5277 24.2294 27.5277 23.3741ZM13.0735 23.3741V16.147C13.0735 15.2917 12.3802 14.5983 11.5249 14.5983C10.6695 14.5983 9.97619 15.2917 9.97619 16.147V23.3741C9.97619 24.2294 10.6695 24.9228 11.5249 24.9228C12.3802 24.9228 13.0735 24.2294 13.0735 23.3741Z"
      fill="#0F172A"
    />
  </svg>
</div>
<div class="flex flex-col w-[60%]">
  <span class="font-semibold  text-3xl text-vendblocblue">{{cardCount}}</span>
  <span class=" text-vendblocblue opacity-70">{{ cardName }}</span>
</div>
<div class="-mt-4">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M18.0263 19.3315C18.8474 19.3315 19.513 18.6658 19.513 17.8448C19.513 17.0237 18.8474 16.358 18.0263 16.358C17.2052 16.358 16.5396 17.0237 16.5396 17.8448C16.5396 18.6658 17.2052 19.3315 18.0263 19.3315Z"
      stroke="black"
      stroke-width="2.97344"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.4335 19.3315C29.2546 19.3315 29.9202 18.6658 29.9202 17.8448C29.9202 17.0237 29.2546 16.358 28.4335 16.358C27.6124 16.358 26.9468 17.0237 26.9468 17.8448C26.9468 18.6658 27.6124 19.3315 28.4335 19.3315Z"
      stroke="black"
      stroke-width="2.97344"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.61953 19.3315C8.44063 19.3315 9.10625 18.6658 9.10625 17.8448C9.10625 17.0237 8.44063 16.358 7.61953 16.358C6.79844 16.358 6.13281 17.0237 6.13281 17.8448C6.13281 18.6658 6.79844 19.3315 7.61953 19.3315Z"
      stroke="black"
      stroke-width="2.97344"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
</p-card>

<div class="bg-white flex space-x-3 pt-8 pb-4 px-3 w-[280px] rounded-md" >
  <div
    class="flex items-center justify-center rounded-full h-14 w-14 {{iconBackgroundColor}}"
  >
    <svg
      class="m-1"
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
    >
      <path
        d="M37.336 12.0172V13.0497C37.336 13.905 36.6426 14.5983 35.7873 14.5983H35.2711L33.5861 26.3932C33.3681 27.9191 32.0613 29.0525 30.5199 29.0525H6.98404C5.44266 29.0525 4.13585 27.9191 3.91781 26.3932L2.23286 14.5983H1.71664C0.86132 14.5983 0.167969 13.905 0.167969 13.0497V12.0172C0.167969 11.1619 0.86132 10.4685 1.71664 10.4685H6.062L12.9523 0.994515C13.623 0.0722844 14.9144 -0.131688 15.8367 0.539078C16.759 1.20984 16.9629 2.50124 16.2922 3.42353L11.1685 10.4685H26.3355L21.2118 3.42347C20.541 2.50124 20.745 1.20978 21.6673 0.539014C22.5894 -0.131753 23.881 0.0721553 24.5517 0.994451L31.4419 10.4685H35.7873C36.6426 10.4685 37.336 11.1619 37.336 12.0172ZM20.3006 23.3741V16.147C20.3006 15.2917 19.6073 14.5983 18.752 14.5983C17.8967 14.5983 17.2033 15.2917 17.2033 16.147V23.3741C17.2033 24.2294 17.8967 24.9228 18.752 24.9228C19.6073 24.9228 20.3006 24.2294 20.3006 23.3741ZM27.5277 23.3741V16.147C27.5277 15.2917 26.8344 14.5983 25.9791 14.5983C25.1238 14.5983 24.4304 15.2917 24.4304 16.147V23.3741C24.4304 24.2294 25.1238 24.9228 25.9791 24.9228C26.8344 24.9228 27.5277 24.2294 27.5277 23.3741ZM13.0735 23.3741V16.147C13.0735 15.2917 12.3802 14.5983 11.5249 14.5983C10.6695 14.5983 9.97619 15.2917 9.97619 16.147V23.3741C9.97619 24.2294 10.6695 24.9228 11.5249 24.9228C12.3802 24.9228 13.0735 24.2294 13.0735 23.3741Z"
        fill="#0F172A"
      />
    </svg>
  </div>
  <div class="flex flex-col w-[60%]">
    <span class="font-semibold  text-3xl text-vendblocblue">{{cardCount}}</span>
    <span class=" text-vendblocblue opacity-70">{{ cardName }}</span>
  </div>
  <div class="-mt-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18.0263 19.3315C18.8474 19.3315 19.513 18.6658 19.513 17.8448C19.513 17.0237 18.8474 16.358 18.0263 16.358C17.2052 16.358 16.5396 17.0237 16.5396 17.8448C16.5396 18.6658 17.2052 19.3315 18.0263 19.3315Z"
        stroke="black"
        stroke-width="2.97344"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.4335 19.3315C29.2546 19.3315 29.9202 18.6658 29.9202 17.8448C29.9202 17.0237 29.2546 16.358 28.4335 16.358C27.6124 16.358 26.9468 17.0237 26.9468 17.8448C26.9468 18.6658 27.6124 19.3315 28.4335 19.3315Z"
        stroke="black"
        stroke-width="2.97344"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.61953 19.3315C8.44063 19.3315 9.10625 18.6658 9.10625 17.8448C9.10625 17.0237 8.44063 16.358 7.61953 16.358C6.79844 16.358 6.13281 17.0237 6.13281 17.8448C6.13281 18.6658 6.79844 19.3315 7.61953 19.3315Z"
        stroke="black"
        stroke-width="2.97344"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
