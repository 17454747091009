import { Component, OnInit } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { OrderComponent } from './components/order/order.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingService } from '@data-access/services';
@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    imports: [HttpClientModule,
        // CommonModule,
        RouterOutlet,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        OrderComponent,
        HeaderComponent,
        FooterComponent,
        RouterModule,
        NgxSpinnerModule,
        AuthLayoutComponent, LoadingComponent]
})


export class AppComponent implements OnInit {
  title = 'VendBlocEase';
  constructor( private router: Router, private loadingService:LoadingService){
  }

  ngOnInit(){
   this.Loader();
  }

Loader(){
  this.router.events.subscribe((event)=>{
    if(event instanceof NavigationStart){
      this.loadingService.isLoading.next(true);
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
      this.loadingService.isLoading.next(false);
    }
});
}
}